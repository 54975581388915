// import { sendMessage, MESSAGES } from './tab_channel'

import { postDOM, webTranslate } from './mounting'
import { CURRENCY_CONFIG, currentLanguage } from './config'

const CURRENCY_COOKIE = 'currency'

const getCurrencyCookie = () => {
    const cookies = decodeURIComponent(document.cookie).split(';')

    const cookie = cookies.find(c => {
        return c.trim().startsWith(CURRENCY_COOKIE + '=')
    })
    return cookie
    // if setting currency cookie is messed up, this will return undefined
}

export const getCurrency = () => {
    let cookie = getCurrencyCookie()
    if (cookie) return cookie.split('=')[1]
}

export const setCurrency = currency => {
    const originalCurrency = getCurrency()
    // console.log({originalCurrency, currency})
    document.cookie = `${CURRENCY_COOKIE}=${originalCurrency}; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
    document.cookie = `${CURRENCY_COOKIE}=${currency}; max-age=${60*60*24*365}; path=/`
    // reload all tabs to get changed currency
    // experimental - cause performance issues if many tabs are open
    // sendMessage(MESSAGES.reload)
}

const regexToRemoveDecimalsInCZK = /[,.][0-9][0-9]/

/**
 * @deprecated
 */
export const formatCurrency2 = ( value, currency, language ) => {
    // helper function for rounding
    // returns formatted price as string
    // TODO read currency decimals from core settings.CURRENCIES_DECIMALS

    let res = new Intl.NumberFormat( language, {
        style: 'currency',
        currency: currency,
        roundingMode:'ceil',

    }).format(value)


    if (currency !== 'CZK') return res

    return res.replace(regexToRemoveDecimalsInCZK, '')
}

/**
 * @deprecated
 */
export const formatCurrency3 = (value, currency, language) => {

    const str = formatCurrency2(value, currency, language)

    if (currency === 'CZK') return str

    let i = str.length -1

    while (!str[i].match(/(\.|,)/)) {
        i--
    }

    const afterDecimal = str.substring(i+1, str.length)


    return `${str.substring(0,i)}${str[i]}<span class="small-1 align-top">${afterDecimal.match(/[0-9]+/)[0]}</span>${afterDecimal.match(/[^0-9]+/)?.[0] ?? ''}`
}

/**
 * @deprecated
 */
export const formatCurrency = ( value, currency, language ) => {
    // helper function for rounding
    // returns formatted price as string
    // TODO read currency decimals from core settings.CURRENCIES_DECIMALS

    let places
    switch (currency) {
    case 'EUR':
    case 'USD':
    case 'GBP':
        places = 2
        break
    case 'CZK':
        places = 0
        break
    default:
        return value
    }
    value = parseFloat(value).toFixed(places).toString()
    switch (language) {
    case 'cs':
    case 'fr':
        // replace decimal dot for a comma
        value = value.replace(/\./g, ',')
        break
    }
    return value
}





const getDecimalPlaces = currency => CURRENCY_CONFIG.customDecimalPlaces[currency] ?? CURRENCY_CONFIG.decimalPlaces

const getGroupSize = locale => CURRENCY_CONFIG.customGroupSize[locale] ?? CURRENCY_CONFIG.groupSize

const getGroupDelimeter = locale => CURRENCY_CONFIG.customGroupDelimeter[locale] ?? CURRENCY_CONFIG.groupDelimeter


const _splitNumberIntoGroups = (str, groupSize, groupDelimeter) =>
    (str.match(new RegExp(`(\\d+?)(?=(\\d{${groupSize}})+(?!\\d)|$)`, 'g')))?.join(groupDelimeter) ?? str

const splitNumberIntoGroups = (str, locale) => _splitNumberIntoGroups(str, getGroupSize(locale), getGroupDelimeter(locale))


/**
 * @typedef {{
 *  integer: String
 *  fraction: String | null
 * }} RawPrice
 *
 * @typedef {{
 *  omitSymbol: boolean | undefined
 *  decimals: number | undefined
 *  substituteZero: boolean | string | undefined
 * }} FormatOptions
 * substituteZero == true - return translation of PRICE_ZERO if price is equal 0.0
 * substituteZero: string - return value of substituteZero if price is equal 0.0
 *
 * @callback FormatCurrency
 * @param {number} value
 * @param {String} currency
 * @param {String} locale
 * @param {FormatOptions} options
 */

/**
 *
 * @type {FormatCurrency}
 * @returns {RawPrice}
 */
const getRawPrice = (value, currency, locale = currentLanguage, {decimals = undefined}) => {
    const decPlaces = decimals ?? getDecimalPlaces(currency)

    const str = value.toFixed(decPlaces)


    if (decPlaces == 0) {

        return {
            integer: splitNumberIntoGroups(str, locale),
            fraction: null,
        }
    }

    const [integer, fraction] = str.split('.')

    return {
        integer: splitNumberIntoGroups(integer, locale), fraction: fraction,
    }
}



/**
 * @param {String} locale
 * @param {String} currency
 * @param {RawPrice & {options: FormatOptions}} args
 */
const formatRawPrice = (locale = currentLanguage, currency, {integer, fraction, options = {}}) => {
    let str = CURRENCY_CONFIG.customFormatString[locale] ?? CURRENCY_CONFIG.formatString

    if (typeof str === 'object') str = str[currency] ?? str.default

    str = str.replace('{CURRENCY}', options.omitSymbol ? '' : webTranslate(currency))
    str = str.replace('{INTEGER}', integer)


    if (fraction) return str.replace('{FRACTION}', fraction)

    // if fraction is not present comma/period is also removed from final string
    str = str.replace(/(\.|,)\{FRACTION\}/, '')

    return str
}

/**
 * @type {FormatCurrency}
 * @return {String}
 */
export const getFormatedPrice = (value, currency = getCurrency(), locale = currentLanguage, options = {}) => {
    if (value === 0.0 && options.substituteZero) {
        if (options.substituteZero == true) return webTranslate('PRICE_ZERO')
        else return options.substituteZero
    }

    return formatRawPrice(locale, webTranslate(currency), {...getRawPrice(value, currency, locale, options), options})
}

/**
 * @type {FormatCurrency}
 * @return {String}
 */
export const getCurrencyHTML = (value, currency = getCurrency(), locale, options = {}) => {
    if (value === 0.0 && options.substituteZero) {
        if (options.substituteZero == true) return webTranslate('PRICE_ZERO')
        else return options.substituteZero
    }
    const raw = getRawPrice(value, currency, locale, options)

    if (raw.fraction) raw.fraction = `<sup class="price-fraction">${raw.fraction}</sup>`


    return formatRawPrice(locale, webTranslate(currency), {...raw, options})
}

const initHook = async () => {

    // const {Popover} = await import('bootstrap')


    // for (const el of  document.querySelectorAll('.price-animate')) {
    //     el.classList.add('opacity-0')
    // }
    for (const el of document.querySelectorAll('.price-container')) {

        const amount_str = el.dataset['agpAmount']?.match(/[0-9]+((\.|,)[0-9]+)? [A-Z]{3}/)
        if (amount_str === null) continue

        const amount = parseFloat(
            // match any digitsequence or 2 digit sequences separated by dot or comma
            amount_str[0]
        )
        el.innerHTML = getCurrencyHTML(amount)




        /*
        const amounts = {}
        for (const key of Object.keys(el.dataset)) {
            const currency = key.match(/price([A-Z][a-z]{0,2})/)?.[1]?.toUpperCase()
            if (!currency) continue
            amounts[currency] = parseFloat(el.dataset[key])
        }



        if (el.dataset.pricePopover !== 'true') continue

        const content = document.createElement('table')



        new Popover(el, {
            placement: 'left',
            template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-inner">klokan</div></div>',
            sanitize: true,
            content: 'ahoj',

            trigger: 'hover focus'
        }).show()*/
    }

    // for (const el of  document.querySelectorAll('.price-animate')) {
    //     el.classList.remove('opacity-0')
    // }
}

postDOM(initHook)