import { currentLanguage } from './config'



// resolve code running after DOMContent loaded
export const postDOM = (callable, ...args) => {
    var loaded = false
    const run = () => {
        if (loaded) return
        loaded = true
        callable(...args)
    }

    if (document.readyState !== 'loading') run()
    else document.addEventListener('DOMContentLoaded', run)
}

export const interpolate = (fmt, obj, named) => {
    if (named) {
        return fmt.replace(/%\(\w+\)s/g, (match)=>String(obj[match.slice(2,-2)]))
    } else {
        return fmt.replace(/%s/g, () => String(obj.shift()))
    }
}

export const webTranslate = (ident, count = 1) => {
    // accepts translation ident or id and returns translated
    // text or original input if translation does not exsist
    // this function has python counterpart (templatetags/mancub_cms.py)
    // TODO fallback languages?
    const webTranlations = JSON.parse(document.getElementById('translation-data').textContent)
    // console.log('WebTranslation ' + ident)

    if (currentLanguage === 'cs') {
        // languages with multiple plurals (2, 3, 4 is different from 0, 5, 6, ...)
        if (count !== 1) {
            // determine form of plural
            if ([2, 3, 4].includes(count)) {
                // e.g. 4 obrázky
                count = 2
            }
            else {
                // e.g. 5 obrázků
                count = 5
            }

        }
    }
    else {
        // languages with single plural (0, 2, 3, 4, 5, 6 are all second form of plural)
        if (count !== 1) count = 2
    }

    for (let index = 0; index < webTranlations.length; index++) {
        const translation = webTranlations[index]
        if ((translation.ident === ident || translation.id === ident) && ((translation.count === undefined && count === 1) || translation.count === count)) {
            return translation.translations__text
        }
    }
    // no translation match
    return ident
}

export const addSharedAppGlobals = async app => {
    app.config.globalProperties.webTranslate = webTranslate
    app.config.globalProperties.interpolate = interpolate

    app.provide('webTranslate', webTranslate)
    app.provide('interpolate', interpolate)

    // app.config.globalProperties.gettext = window.gettext
    // app.config.globalProperties.ngettext = window.ngettext
    // app.config.globalProperties.interpolate = window.interpolate
    // app.config.globalProperties.django = window.django

    // // TODO switching currencies
    // app.config.globalProperties.currency = window.gettext('CZK')
    //if (process.env.NODE_ENV === 'production') {
    const injectSentry = vueApp => () => {
        vueApp.mixin(getSentry().createTracingMixins({ trackComponents: true }))
        getSentry().attachErrorHandler(vueApp)
    }

    const {subscribeSentryInit, getSentry} = (await import('vendor_modules/sentry'))
    if (getSentry()) injectSentry(app)()
    else {
        subscribeSentryInit(injectSentry(app))
    }
    subscribeSentryInit(injectSentry(app))

    //}
    return app
}