// BroacastChannel is not supported on older browsers more info: https://caniuse.com/broadcastchannel
const CHANNEL = typeof BroadcastChannel === 'undefined' ? null : new BroadcastChannel('app-data')

export const MESSAGES = {
    reload: 'RELOAD'
}

export const sendMessage = (type, data) => {
    if (!CHANNEL) return
    CHANNEL.postMessage({type, data})
}

// cross-tab communication
// currently used for reload on currency change
const resolveMessage = e => {
    if (!e.data.type) return console.warn(e)

    switch(e.data.type) {

    case MESSAGES.reload:
        location.reload()
        break
    }
}

let isInitialised = false

export const setup = () => {
    if (!CHANNEL || isInitialised) return
    isInitialised = true

    CHANNEL.addEventListener('message', resolveMessage)
}