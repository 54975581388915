const CUSTOM_EVENTS = {
    init: 'analyticsInit'
}

var initialInit = false

const subscribeAnalyticsInit = f => {
    // run if initial event already dispatched
    if (initialInit) f()
    addEventListener(CUSTOM_EVENTS.init, f)
}

const triggerAnalyticsInit = (initial) => {
    if (initial) initialInit = true
    dispatchEvent(new CustomEvent(CUSTOM_EVENTS.init))
}


export {subscribeAnalyticsInit, triggerAnalyticsInit}