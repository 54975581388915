import 'vite/modulepreload-polyfill'

import 'utils/mounting'
import 'utils/currency'


// load all index.js files in components/*
// replaces e.g. import './components/hero_search/index.js'
Object.values(import.meta.glob('./components/*/index.js')).forEach(c => c())

// load all direct .js files in vendor_modules
// replaces e.g. import './vendor_modules/photoswipe.js'
Object.values(import.meta.glob('./vendor_modules/*.js')).forEach(c => c())

import {setup as setupTabChannel} from './utils/tab_channel'

import {triggerAnalyticsInit} from 'utils/analytics'

triggerAnalyticsInit(true)
setupTabChannel()